<template>
  <div class="about" style="text-align: left">
    <hr style="size: 2px;padding-top:80px">

    <v-container style="margin-bottom: 50px">
      <section style="margin-bottom: 50px">
        <div class="row" style="justify-content: center">
          <h1 style="font-size: 50px;font-weight: bold">{{ $t('contactUs') }}</h1>

        </div>
        <div class="row justify-content-center">
          <div class="section_tittle text-center">
            <h2></h2>
          </div>
        </div>
      </section>
      <v-row v-for="(d,i) in contactList" :key="i" style="margin-bottom: 50px">
        <section style="margin-bottom: 50px" v-if="d.email">
          <div class="row" style="justify-content: center">
            <div class="subTitle1" style="text-align: left" v-html="getTranslate(d.title)">
            </div>
          </div>
        </section>
        <v-col cols="12" md="6" sm="6" class="animate__animated animate__delay-1s  animate__slideInUp" v-if="d.email">
          <v-list three-line>
            <template>
              <v-list-item>
                <v-list-item-action>
                  <v-btn fab style="color: green">
                    <v-icon>mdi-map-marker-radius</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("visitUs") }}</v-list-item-title>
                  <v-list-item-subtitle v-html="getTranslate(d.address)"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-list two-line>
            <template>
              <v-list-item>
                <v-list-item-action>
                  <v-btn fab style="color: green">
                    <v-icon>mdi-email</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("mailUs") }}</v-list-item-title>
                  <v-list-item-subtitle v-html="d.email"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-list two-line>
            <template>
              <v-list-item>
                <v-list-item-action>
                  <v-btn fab style="color: green">
                    <v-icon>mdi-phone</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("callUs") }}</v-list-item-title>
                  <v-list-item-subtitle v-html="d.phoneNumber"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

        </v-col>
        <v-col cols="12" md="6" sm="6" v-if="d.email">
          <v-img :src="d.url"></v-img>
        </v-col>
        <v-col cols="12" md="12" sm="12" v-if="!d.email"
               :style="$vuetify.breakpoint.mobile ? 'text-align: center;' : 'text-align: center;padding-left: 10%;padding-right: 10%'">
          <b>
            <h1><p style="font-family: 'Khmer OS Muol Light'" v-html="getTranslate(d.title)">
            </p></h1>
          </b>
        </v-col>
        <v-col cols="12" md="12" sm="12" v-if="!d.email">
          <div class="d-flex flex-column justify-space-between  align-center">
            <v-img v-if="d.url" :src="d.url" :style="$vuetify.breakpoint.mobile ? 'width: 100%' : 'width: 60%'"></v-img>
          </div>
        </v-col>


        <v-divider inset></v-divider>
      </v-row>
      <div style="text-align: center">
        <h2>{{ $t("connectUsThroughSocialMediaOrVisitOurShop") }}</h2>
      </div>
      <br>
      <div class="row justify-content-center">
        <div class="section_tittle text-center">
          <h2></h2>
        </div>
      </div>
      <br>
      <div style="text-align: center">
        <v-btn
            class="mx-4 blue--text"
            icon
            large
            :href="dataFooter && dataFooter.facebookUrl || ''"
            target="_blank"
        >
          <v-icon size="24px">
            mdi-facebook
          </v-icon>
        </v-btn>
        <v-btn
            class="mx-4 green--text"
            icon
            large
            :href="dataFooter && dataFooter.twitterUrl || ''"
            target="_blank"
        >
          <v-icon size="24px">
            mdi-twitter
          </v-icon>
        </v-btn>
        <v-btn
            class="mx-4 light-blue--text"
            icon
            large
            :href="dataFooter && dataFooter.linkInUrl || ''"
            target="_blank"
        >
          <v-icon size="24px">
            mdi-linkedin
          </v-icon>
        </v-btn>
        <v-btn
            class="mx-4 teal--text"
            icon
            large
            :href="dataFooter && dataFooter.instagramUrl || ''"
            target="_blank"
        >
          <v-icon size="24px">
            mdi-instagram
          </v-icon>
        </v-btn>
        <v-btn
            class="mx-4 blue--text"
            icon
            :href="dataFooter && dataFooter.telegramUrl || ''"
            target="_blank"
        >
          <v-icon size="24px">
            mdi-send
          </v-icon>
        </v-btn>
      </div>
    </v-container>
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1037.2010336257629!2d103.19426358716215!3d13.11415648746183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31054bb936fbebf5%3A0x49ceab764fc6c66d!2zR3JlZW4gSG91c2UgLSDhnpXhn5LhnpHhn4fhnpThn4Phno_hnoQ!5e0!3m2!1skm!2skh!4v1660361372575!5m2!1skm!2skh"
        width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"></iframe>

  </div>
</template>

<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  data() {
    return {
      contactList: [],
      windowSize: {
        x: 0,
        y: 0
      },
      dataFooter: {}
    }
  },
  mixins: [MyMixin],
  methods: {
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    fetchContact() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getContact;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.contactList = res.data.data;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    getVideoLink(val) {
      if (val && val.split('youtu.be').length === 2) {
        return val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split('/watch?v=').length > 0) {
        return "https://www.youtube.com/embed/" + val.split('/watch?v=')[1].split("&list=")[0].split('&t=')[0];
      }
    },
    fetchHeaderFooter() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getHeaderFooter;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.dataFooter = res.data.data;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  mounted() {
    this.onResize();
  },
  created() {
    let vm = this;
    vm.fetchContact();
    vm.fetchHeaderFooter();
  },
  watch: {},
}
</script>

<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

.mission {
  background: #FBFBFB;
  height: 600px;
  width: 100%;
  position: relative;
  margin-top: 96px;
  padding: 60px 96px;
  box-sizing: border-box;
  margin-bottom: 50px;
}


.mission .line {
  width: 1px;
  height: 189px;
  background: #D8D9DC;
  position: absolute;
  left: 55.24%;
  top: 67px;
  display: none;
}

.mission .r-mess {
  float: right;
  width: 405px;
  height: 190px;
  position: relative;
}

body {
  color: #fff;
  line-height: 1.5;
  font-size: 14px;
  font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.mission .l-mess .label {
  font-size: 30px;
  color: #2A2E2E;
  letter-spacing: 0;
  font-family: 'TencentSansw7';
}

.mission .l-mess {
  float: left;
}

.mission .r-mess .label {
  font-size: 20px;
  color: #2A2E2E;
  letter-spacing: 0;
  line-height: 36px;
}

.mission .r-mess .desc {
  font-size: 14px;
  color: #5F6464;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
}

.mission .r-mess:before {
  content: ' ';
  width: 1px;
  position: absolute;
  left: -60px;
  top: 0;
  display: block;
  bottom: 0;
  background: #D8D9DC;
}

.mission .text-bg {
  width: 824px;
  position: absolute;
  left: 82px;
  bottom: 0;
}


/*Goal*/
.goal {
  background: #FBFBFB;
  height: 300px;
  width: 100%;
  position: relative;
  margin-top: 96px;
  padding: 60px 96px;
  box-sizing: border-box;
  margin-bottom: 50px;
}


.goal .line {
  width: 1px;
  height: 160px;
  background: #D8D9DC;
  position: absolute;
  left: 55.24%;
  top: 67px;
  display: none;
}

.goal .r-mess {
  float: right;
  width: 405px;
  height: 190px;
  position: relative;
}

.goal .l-mess .label {
  font-size: 30px;
  color: #2A2E2E;
  letter-spacing: 0;
  font-family: 'TencentSansw7';
}

.goal .l-mess {
  float: left;
}

.goal .r-mess .label {
  font-size: 20px;
  color: #2A2E2E;
  letter-spacing: 0;
  line-height: 36px;
}

.goal .r-mess .desc {
  font-size: 14px;
  color: #5F6464;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
}

.goal .r-mess:before {
  content: ' ';
  width: 1px;
  position: absolute;
  left: -60px;
  top: 0;
  display: block;
  bottom: 0;
  background: #D8D9DC;
}

.goal .text-bg {
  width: 824px;
  position: absolute;
  left: 82px;
  bottom: 0;
}
</style>
